import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Name', 'name', true, 'asc', 'left'),
  new TableColumn('Street', 'street', true, 'asc', 'left'),
  new TableColumn('Suburb/Town', 'suburb', true, 'asc', 'left'),
  new TableColumn('Phone', 'defaultPhone', true, 'asc', 'left'),
  new TableColumn('Email', 'defaultEmail', true, 'asc', 'left'),
  new TableColumn('ABN', 'abn', true, 'asc', 'centered'),
  new TableColumn('Contact', 'defaultContact', true, 'asc', 'left'),
  new TableColumn('Active', 'active', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export default Columns
