<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <vendor-list-side-menu
          :filter="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(c, idx) in entitySummaries"
                :class="{ 'is-selected' : selectedRow === idx }"
                @click="highlightSelected(idx, $event)"
                :key="c.id">
                <td>
                  <router-link
                    :to="{ path: c.vendorId }"
                    append>{{ c.name }}</router-link>
                </td>
                <td class="is-address">{{ c.street }}</td>
                <td>{{ c.suburb }}</td>
                <td>{{ c.defaultPhone }}</td>
                <td class="is-email">
                  <div>{{ c.defaultEmail }}</div>
                </td>
                <td class="is-abn">{{ c.abn | formatABN }}</td>
                <td>{{ c.defaultContact }}</td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ c.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i
                      class="mdi mdi-24px"
                      :class="[ c.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td>
                  <router-link
                    class="button is-primary is-small is-inverted"
                    :to="{ path: c.vendorId }"
                    append>
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import VendorService from './VendorService'
import VendorListSideMenu from './VendorListSideMenu'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import Columns from './columns'
import ProtoBuf from 'protobufjs'
import StoreMixin from './storeMixin'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _transform from 'lodash/transform'
import { Emailer } from '@/classes'

export default {
  name: 'VendorListView',
  components: {
    AppDetailHeader,
    VendorListSideMenu,
    BulmaTable
  },
  filters: {
    formatABN(value) {
      const abn = value || ''
      return `${abn.slice(0, 2)} ${abn.slice(2, 5)} ${abn.slice(5, 8)} ${abn.slice(8, 11)}`
    }
  },
  mixins: [StoreMixin],
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        name: '',
        street: '',
        suburb: '',
        abn: '',
        active: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  async created() {
    await this.getData()
    this.$eventHub.$on(EventHubTypes.ToggleSideMenu, data => {
      this.toggle = false
      setTimeout(() => {
        this.toggle = true
      }, 200)
    })
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter = {
        name: '',
        street: '',
        suburb: '',
        abn: '',
        active: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      }
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    serialiseFilter() {
      const entityProto = require('../../assets/proto/vendor/VendorFilter.proto')
      const filterMessage = entityProto.VendorFilter.VendorFilter
      const buffer = filterMessage.encode(this.filter).finish()
      const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
      return b64encoded
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true

      try {
        this.retrieveFilter()
        const res = await VendorService.getListing(this.serialiseFilter())
        this.entitySummaries = res.data
        this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      } catch (err) {
        console.log(err)
      }

      this.isTableLoading = false
    },
    print() {
      let params = Object.assign(
        {
          companyid: this.$userInfo.companyId
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      params = _transform(params, function(result, value, key) {
        result[key.toLowerCase()
        .replace('sortorder', 'sortdirection')] = value
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e.toLowerCase()}=${params[e]}`)
      //     .join('&')
      //     .replace('sortorder', 'sortdirection')
      // )
      const emailer = new Emailer()
      emailer.subject = 'Vendor List Report'
      emailer.reportName = 'rptVendorList'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    addNew() {
      this.addStoreItem()
    }
  }
}
</script>
<style lang="scss" scoped>
.zoomIn {
  animation: zoomIn 0.5s;
}
.email {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
